<template>
  <div class="onde">
     <!-- Banner !-->
    <div
      class="flex items-center w-full h-38em md:h-45em bg-layout_green bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8"
    >
      <div class="mx-2 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12 lg:col-span-7">
            <span class="text-white font-Montserrat text-xl">ONDE NOS ENCONTRAR</span>
            <h1
              class="text-white font-Montserrat text-4xl md:text-6xl mt-3"
            >
              Nos encontre em uma de nossas unidades
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- onde !-->
    <div id="onde" class="py-12 md:py-28 mx-2 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-6">
                <div class="w-full py-3 px-3 md:py-8 md:px-8 bg-terciary">
                    <h3
                    class="text-gray-900 font-Montserrat font-semibold text-xl md:text-2xl mb-5"
                    >
                        Curitiba
                    </h3>
                    <a class="text-gray-600 font-Montserrat text-base md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            R. Nicolau José Gravina, 2066 - Cascatinha Curitiba - PR <br/>CEP: 82015-080
                        </span>
                    </a>
                    <br/><br/>
                    <a href="tel:4136732330" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            (41) 3374-3735
                        </span>
                    </a>
                    <br/><br/>
                    <a href="mailto:boscardin@pedreirasboscardin.com.br" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                            </svg>
                            boscardin@pedreirasboscardin.com.br
                        </span>
                    </a>
                    <br/><br/>
                    <a target="_blank" href="https://goo.gl/maps/y75A3hgNkVMwh26G8" class="text-black font-Montserrat text-base font-semibold cursor-pointer">
                        VER NO GOOGLE MAPS 
                    </a>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="w-full py-3 px-3 md:py-8 md:px-8 bg-terciary">
                    <h3
                    class="text-gray-900 font-Montserrat font-semibold text-xl md:text-2xl mb-5"
                    >
                        Ponta Grossa
                    </h3>
                    <a class="text-gray-600 font-Montserrat text-base md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            Rod. BR 373, KM 180 + 9KM - s/n. - Bocaina Ponta Grossa - PR <br/>CEP: 84125-200
                        </span>
                    </a>
                    <br/><br/>
                    <a href="tel:42991131716" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            (42) 9 91131716
                        </span>
                    </a>
                    <br/><br/>
                    <a href="mailto:pontagrossa@pedreirasboscardin.com.br" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                            </svg>
                            pontagrossa@pedreirasboscardin.com.br
                        </span>
                    </a>
                    <br/><br/>
                    <a target="_blank" href="https://www.google.com/search?q=boscardin+pedreira&tbm=lcl&sxsrf=AOaemvJ07aFadQtKdoa5rcfw8iig9Hw6dQ%3A1637150326508&ei=du6UYaecHrTN1sQPmK-aoA4&oq=boscardin+pedreira&gs_l=psy-ab.3...0.0.0.68507.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.F0ScYS0X7L4#rlfi=hd:;si:9707196602449858305,l,ChJib3NjYXJkaW4gcGVkcmVpcmFaFCISYm9zY2FyZGluIHBlZHJlaXJhkgEGcXVhcnJ5qgEaEAEqFiISYm9zY2FyZGluIHBlZHJlaXJhKAw;mv:[[-24.9451076,-48.915720799999995],[-25.564207699999997,-50.7449237]]" class="text-black font-Montserrat text-base font-semibold cursor-pointer">
                        VER NO GOOGLE MAPS 
                    </a>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="w-full py-3 px-3 md:py-8 md:px-8 bg-terciary">
                    <h3
                    class="text-gray-900 font-Montserrat font-semibold text-xl md:text-2xl mb-5"
                    >
                        Piraquara
                    </h3>
                    <a class="text-gray-600 font-Montserrat text-base md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            R. Jacob Valenga, 33.333 Vila Franca Piraquara - PR <br/>CEP: 83305-000
                        </span>
                    </a>
                    <br/><br/>
                    <a href="tel:4136732330" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            (41) 3673-2330 ou (41) 3673-2680
                        </span>
                    </a>
                    <br/><br/>
                    <a href="mailto:piraquara@pedreirasboscardin.com.br" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                            </svg>
                            piraquara@pedreirasboscardin.com.br
                        </span>
                    </a>
                    <br/><br/>
                    <a target="_blank" href="https://www.google.com/search?q=boscardin+pedreira&tbm=lcl&sxsrf=AOaemvJ07aFadQtKdoa5rcfw8iig9Hw6dQ%3A1637150326508&ei=du6UYaecHrTN1sQPmK-aoA4&oq=boscardin+pedreira&gs_l=psy-ab.3...0.0.0.68507.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.F0ScYS0X7L4#rlfi=hd:;si:18367537935449571881,l,ChJib3NjYXJkaW4gcGVkcmVpcmFaFCISYm9zY2FyZGluIHBlZHJlaXJhkgEGcXVhcnJ5qgEaEAEqFiISYm9zY2FyZGluIHBlZHJlaXJhKAw;mv:[[-24.9451076,-48.915720799999995],[-25.564207699999997,-50.7449237]]" class="text-black font-Montserrat text-base font-semibold cursor-pointer">
                        VER NO GOOGLE MAPS 
                    </a>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="w-full py-3 px-3 md:py-8 md:px-8 bg-terciary">
                    <h3
                    class="text-gray-900 font-Montserrat font-semibold text-xl md:text-2xl mb-5"
                    >
                        Irati
                    </h3>
                    <a class="text-gray-600 font-Montserrat text-base md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            Rua Santa Bertila S/N Riozinho IRATI - PR <br/>CEP: 84505-650
                        </span>
                    </a>
                    <br/><br/>
                    <a href="tel:4234232525" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            (42) 3423-2525
                        </span>
                    </a>
                    <br/><br/>
                    <a href="mailto:irati@pedreirasboscardin.com.br" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                            </svg>
                            irati@pedreirasboscardin.com.br
                        </span>
                    </a>
                    <br/><br/>
                    <a target="_blank" href="https://goo.gl/maps/AnvvpoTLXzyWAa3R9" class="text-black font-Montserrat text-base font-semibold cursor-pointer">
                        VER NO GOOGLE MAPS 
                    </a>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6">
                <div class="w-full py-3 px-3 md:py-8 md:px-8 bg-terciary">
                    <h3
                    class="text-gray-900 font-Montserrat font-semibold text-xl md:text-2xl mb-5"
                    >
                        Irati Filial
                    </h3>
                    <a class="text-gray-600 font-Montserrat text-base md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                            </svg>
                            Rod. BR 277 - Km 256 - s/n.º - Pinho Irati - PR <br/>CEP: 84508-599
                        </span>
                    </a>
                    <br/><br/>
                    <a href="tel:4299741144" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                            </svg>
                            (42) 9974-1144
                        </span>
                    </a>
                    <br/><br/>
                    <a href="mailto:irati@pedreirasboscardin.com.br" class="text-gray-600 font-Montserrat text-base cursor-pointer md:inline-block">
                        <span class="text-xs md:text-base md:flex">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-primary mb-1 md:mr-2 md:mb-0" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M2.94 6.412A2 2 0 002 8.108V16a2 2 0 002 2h12a2 2 0 002-2V8.108a2 2 0 00-.94-1.696l-6-3.75a2 2 0 00-2.12 0l-6 3.75zm2.615 2.423a1 1 0 10-1.11 1.664l5 3.333a1 1 0 001.11 0l5-3.333a1 1 0 00-1.11-1.664L10 11.798 5.555 8.835z" clip-rule="evenodd" />
                            </svg>
                            irati@pedreirasboscardin.com.br
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!-- Interessado !-->
    <div id="interessado" class="py-12 md:py-28 bg-layout_branco bg-cover bg-no-repeat bg-cente">
      <div class="mx-2 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-8">
            <h2
                class="text-gray-900 font-Montserrat text-2xl md:text-5xl"
              >
                Interessado nos nossos serviços? Entre em contato com a gente.
            </h2>
            <button
              @click="$modal.show('modal_zap')"
              class="border-none bg-secondary text-base font-semibold text-white py-6 font-Montserrat mt-4 w-full hover:bg-primary md:w-60 mt-10"
            >
              Entrar em contato
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
